import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { LoggedinUserState, FeatureFlagsState } from 'app/state';
import { Store } from '@ngxs/store';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { UserProjectRoleEnum } from '@core';
import { Router } from '@angular/router';
import { EMAIL_REGEX } from '@core/constants';

import { UpdateEc2InstanceType } from 'app/state/update-instance';
import { catchError, throwError } from 'rxjs';
import { AlertMessageService } from '@core/services';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';

@Component({
  selector: 'mpr-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserFormComponent implements OnInit, OnChanges {
  @Input() public action = 'add';
  @Input() public allowedExternalDomains: Array<string> = [];
  @Input() public canChangeBedrockAccessStatus = true;
  @Input() public canChangePowerUserStatus = true;
  @ViewChildren('checkboxes')
  public checkboxes!: QueryList<MatCheckbox>;
  @Input() public data: ProjectUserList = {
    ec2Status: '',
    emailId: '',
    firstName: '',
    isPowerUser: false,
    lastName: '',
    machineSize: '',
    projectId: '',
    projectRestrictedDataUseGroups: [],
    roleName: '',
    userId: '',
    userStatus: '',
    powerUserFeatures : {
      isHLBedrockAccess : false
    }
  };
  @Input() public disableInputFields = false;
  @Input() public group = {};
  @Output() public isDataRestrictedChange: EventEmitter<boolean> =
    new EventEmitter();
  @Input() public projectId = '';
  @Input() public projectPoweruser = false;
  @Input() public restrictedGroups: string[] = [];

  public allSelected = false;
  public allowedDomains: Array<string> = [];
  public allowedInternalDomains: Array<string> = [];
  public externalRoles = [
    UserProjectRoleEnum.EXTERNAL_DEVELOPER,
    UserProjectRoleEnum.EXTERNAL_RESEARCHER,
  ];
  public internalRoleNames = [
    UserProjectRoleEnum.ADMIN,
    UserProjectRoleEnum.DEVELOPER,
    UserProjectRoleEnum.RESEARCHER,
  ];
  public isExternalCollaborator = false;
  public isPlatformAdmin = false;
  public isRestrictedGroup = true;
  public isUnsupportedDomain = false;
  public loggedinUserEmail = '';
  public maxLengthInput = 50;
  public nonPowerUserRoles = [
    UserProjectRoleEnum.RESEARCHER,
    UserProjectRoleEnum.EXTERNAL_DEVELOPER,
    UserProjectRoleEnum.EXTERNAL_RESEARCHER,
  ];
  public powerUser = false;
  public regexForEmail = EMAIL_REGEX;
  public roleNames: Array<string> = [];
  public rolesEnum = UserProjectRoleEnum;

  constructor(
    private store: Store,
    private router: Router,
    private alertMsgService: AlertMessageService
  ) {
    if (router.url.includes('platformAdmin/')) {
      this.isPlatformAdmin = true;
    }
  }

  public changeRole(roleName: string): void {
    if (roleName === UserProjectRoleEnum.ADMIN) {
      this.allSelected = true;
      if (
        this.action === 'add' ||
        this.action === 'addToProject' ||
        (this.action === 'edit' && this.isPlatformAdmin)
      )
        this.data.projectRestrictedDataUseGroups = this.restrictedGroups;
    } else {
      this.allSelected = false;
      this.checkboxes.forEach((element) => {
        element.checked = false;
      });
      this.data.projectRestrictedDataUseGroups = [];
    }
  }

  public isNonBedrockUserRole(): boolean {
    return this.canChangeBedrockAccessStatus && (
      this.nonPowerUserRoles.findIndex((x) => x === this.data.roleName) > -1
    );
  }

  public isNonPowerUserRole(): boolean {
    return this.canChangePowerUserStatus && (
      this.nonPowerUserRoles.findIndex((x) => x === this.data.roleName) > -1
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allowedExternalDomains']) {
      if (this.data.emailId !== '') this.onEmailValueChange(true);
    }
  }

  ngOnInit(): void {
    this.isDataRestrictedChange.emit(this.isRestrictedGroup);
    this.loggedinUserEmail = this.store.selectSnapshot(
      LoggedinUserState.getLoggedInUserEmail
    );
    const featureFlags: FeatureFlagsStateModel = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );
    this.allowedInternalDomains = featureFlags.internalDomainList;
    this.data.projectId = this.projectId;
    this.roleNames = this.internalRoleNames;
    if (this.action === 'add') {
      this.restrictedGroups = this.data.projectRestrictedDataUseGroups;
    }
    if (this.data.emailId !== '') {
      this.onEmailValueChange(true);
    }
    if (!this.data.roleName) {
      this.allSelected = false;
    }
  }

  public onCheckboxChange(
    event: MatCheckboxChange,
    restrictedGroup: string
  ): void {
    this.isRestrictedGroup = false;
    this.isDataRestrictedChange.emit(this.isRestrictedGroup);
    this.data.projectRestrictedDataUseGroups = [
      ...this.data.projectRestrictedDataUseGroups,
    ];
    if (event.checked) {
      this.data.projectRestrictedDataUseGroups.push(restrictedGroup);
    } else {
      const indexOfUser =
        this.data.projectRestrictedDataUseGroups.indexOf(restrictedGroup);
      this.data.projectRestrictedDataUseGroups.splice(indexOfUser, 1);
    }
  }

  public onEmailValueChange(isInitFlow = false): void {
    const regex = new RegExp(this.regexForEmail);
    if (regex.test(this.data.emailId)) {
      const enteredEmailDomain: string = this.data.emailId
        .toLowerCase()
        .split('@')[1];
      const isinternalEmail = this.allowedInternalDomains.findIndex(
        (x) => x === enteredEmailDomain
      );
      if (isinternalEmail > -1) {
        this.roleNames = this.internalRoleNames;
        if (!isInitFlow) this.data.roleName = '';
        this.isExternalCollaborator = false;
        this.isUnsupportedDomain = false;
      } else {
        const isExternalDomain = this.allowedExternalDomains.findIndex(
          (x) => x === enteredEmailDomain
        );
        if (isExternalDomain > -1) {
          this.roleNames = this.externalRoles;
          if (!isInitFlow) this.data.roleName = '';
          this.isExternalCollaborator = true;
          if(this.action !== 'edit')
          this.data.isPowerUser = false;
          this.isUnsupportedDomain = false;
        } else {
          this.roleNames = this.internalRoleNames;
          if (!isInitFlow) this.data.roleName = '';
          this.isExternalCollaborator = false;
          if(this.action !== 'edit')
          this.data.isPowerUser = false;
          this.isUnsupportedDomain = true;
        }
      }
    } else {
      this.roleNames = this.internalRoleNames;
      if (!isInitFlow) this.data.roleName = '';
      this.isExternalCollaborator = false;
      if(this.action !== 'edit')
      this.data.isPowerUser = false;
      this.isUnsupportedDomain = false;
    }
  }
}

<ng-container 
*ngIf="{
  projectData: (showSpinner$| async),
  globalAttribute: (globalAttributeLoader$ | async)
} as loaders"
>
<div
  class="mpr-dashboard-loader"
  *ngIf="showloader(loaders); else showProjectForm"
>
  <div class="mpr-noresult-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</div>
</ng-container>
<ng-template #showProjectForm>
  <mpr-project-form
    (proceedToShowPopup)="showConfirmPopup($event)"
    (proceedToShowCancelPopup)="showCancelPopup()"
    [data]="selectedProjectDetails"
    [globalAttributes] = "globalAttributes"
  ></mpr-project-form>
</ng-template>

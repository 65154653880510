import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  Observable,
  catchError,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';
import { ConsoleStatsState } from 'app/state/console-stats/console-stats.state';
import {
  GetProjectCount,
  ResetCountData,
} from 'app/state/console-stats/console-stats.actions';
import { ProjectDetails } from 'app/state/console-stats/console-stats-state-model';
import { Router } from '@angular/router';
import { AlertMessageService } from '@core/services';
import { convertToCSV, saveAsCSV } from '@shared/utils';
import { EXPORT_CSV_ALL_PROJECT_DATA_FIELDS } from '@core/constants';
import { SkipMainContentService } from '@shared';
import { FeatureFlagsState } from 'app/state';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss'],
})
export class AllProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(ConsoleStatsState.getAllProjects)
  public allProjects$!: Observable<Array<ProjectDetails>>;
  @Select(ConsoleStatsState.getProjectCount)
  public projectCount$?: Observable<number>;
  @Select(ConsoleStatsState.getProjectDetails)
  public projects$?: Observable<Array<ProjectDetails>>;
  @ViewChild('skipper') public skipper!: any;
  public exportButtonSpinner = false;
  public pageNo = 1;
  public projectList: Array<ProjectDetails> = [];
  public showLoadMoreSpinner = false;
  public showSpinner = true;

  constructor(
    private skipMainContentService: SkipMainContentService,
    public alertService: AlertMessageService,
    public store: Store,
    private router: Router,
    private location :Location,
  ) {
    const state = location.getState() as { message: any; type: string };
    if (state?.message) {
      timer(1000).subscribe(() => {
        if (state.type === 'success') {
          this.alertService.success({
            body: state.message,
          });
        } else {
          this.alertService.error({
            body: state.message,
          });
        }
      });
    }
  }

  public exportToCSV(): void {
    timer(0).subscribe(() => (this.exportButtonSpinner = true)); // Somehow the variable is not updated in UI hence timer.
    this.store
      .dispatch(new GetProjectCount('True'))
      .pipe(
        withLatestFrom(this.allProjects$),
        catchError((err) => {
          this.alertService.error({
            body: err.error.message,
          });
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, projects]) => {
        if (projects && projects.length > 0) {
          const columnMapping =  this.eliminateUnwantedColumns()
          const projectList = [...projects];
          // Handle export CSV when these values are not returned from the API by passing the values as emtpy string to convertocsv function.
          projectList.forEach((project: ProjectDetails)=>{
            project['rdsEnabled'] = project['rdsEnabled'] || ''
            project['externalProjectAccountsEnabled'] = project['externalProjectAccountsEnabled'] || ''
            project['hasPowerUsers'] = project['hasPowerUsers'] || ''
            project['dwAccess'] = project['dwAccess'] || ''
            project['serviceAccountEnabled'] = project['serviceAccountEnabled'] || ''
          })
            
          this.showSpinner = false;
          const csv = convertToCSV(
            projectList,
            columnMapping
          );
          saveAsCSV(csv, 'All_Projects.csv');
          this.exportButtonSpinner = false;
        }
      });
  }

  public loadMoreProjects(): void {
    this.pageNo++;
    timer(0).subscribe(() => (this.showLoadMoreSpinner = true)); // Somehow the variable is not updated in UI hence timer.
    this.loadProjects();
  }

  public navigateToRoute(routePath: string): void {
    this.router.navigate([`${routePath}`]);
  }

  public ngAfterViewInit(): void {
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.nativeElement.children[0].children[0].children[1].focus();
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetCountData());
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetCountData());
    this.pageNo = 1;
    this.loadProjects();
  }

 private eliminateUnwantedColumns() : any {
    const featureFlagApiKeyMapping = [
      {featureFlag: 'externalProjectAccountsFeatureFlag', apiKey : 'externalProjectAccountsEnabled'},
      {featureFlag: 'rdsFeatureFlag', apiKey : 'rdsEnabled'},
      {featureFlag: 'serviceAccountFeatureFlag', apiKey : 'serviceAccountEnabled'},
      {featureFlag: 'dwFeatureFlag', apiKey : 'dwAccess'},
      {featureFlag: 'isHeavyLiftingFlagAvailable', apiKey : 'hasPowerUsers'},
    ];
    let columnMapping : Record<string, unknown> = EXPORT_CSV_ALL_PROJECT_DATA_FIELDS;
    const featureFlags = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );
    featureFlagApiKeyMapping.forEach((element: any)=>{
      if(featureFlags[element.featureFlag].toLowerCase() !== 'true'){
        columnMapping = Object.fromEntries(Object.entries(columnMapping).filter(([key]) => key !== element.apiKey));
      }
    })
    return columnMapping;
  }
  
  private loadProjects(): void {
    this.store.dispatch(new GetProjectCount('True', this.pageNo));
    this.projects$?.subscribe((projects: Array<ProjectDetails>) => {
      if (projects && projects.length > 0) {
        this.projectList = [...projects];
        this.showSpinner = false;
        this.showLoadMoreSpinner = false;
      }
    });
  }

  
}

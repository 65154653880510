export interface UserList {
  emailId: string;
  isSuccess: boolean;
  [key: string]: any;
  message?: string;
  reason?: string;
  roleName: string;
  userName: string;
  userStatus: string;
}

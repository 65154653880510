import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FeatureFlagsService } from '@shared/services';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeatureFlagsStateModel } from './feature-flags-model';
import {
  GetFeatureFlags,
  HydrateFeatureFlagState,
} from './feature-flags.actions';

const stateDefaults: FeatureFlagsStateModel = {
  dwFeatureFlag: '',
  isAwsWorkspacesAvailable: '',
  isDevelopersAwsWorkspacesAvailable: '',
  externalProjectAccountsFeatureFlag: '',
  isHeavyLiftingFlagAvailable: '',
  isBillingFeatureFlagEnabled: '',
  isForstaFeatureFlagEnabled: '',
  isManageProjects: '',
  isManageUsers: '',
  isProjectAdminConsole: '',
  isRecurringStagingUploadAvailable: '',
  isStagingAccessAvailable: '',
  isStataFeatureFlagAvailable: '',
  isVscodeFeatureFlagAvailable: '',
  internalDomainList: [],
  externalContactUsUrl: `mailto:${environment.supportEmailId}`,
  internalContactUsUrl: '',
  isAccountingFeatureFlagEnabled: '',
  rdsFeatureFlag:'',
  serviceAccountFeatureFlag: '',
};

@State<FeatureFlagsStateModel>({
  defaults: stateDefaults,
  name: 'FeatureFlagsState',
})
@Injectable()
export class FeatureFlagsState {
  constructor(private featureFlagService: FeatureFlagsService) {}

  @Action(GetFeatureFlags)
  public getFeatureFlags(
    ctx: StateContext<FeatureFlagsStateModel>,
  ): Observable<FeatureFlagsStateModel> {
    if (ctx.getState().isManageProjects !== '') return of(ctx.getState());
    return this.featureFlagService.getFeatureFlags().pipe(
      tap((response) => {
        ctx.patchState({ ...response });
      }),
    );
  }

  @Action(HydrateFeatureFlagState)
  public populateFeatureFlagState(
    ctx: StateContext<FeatureFlagsStateModel>,
  ): Observable<any> {
    if (ctx.getState().isStagingAccessAvailable !== '') return of(true);

    return this.featureFlagService.getFeatureFlags().pipe(
      tap((response) => {
        ctx.patchState({ ...response });
        return of(true);
      }),
    );
  }

  @Selector()
  public static returnDWFeatureFlag(state: FeatureFlagsStateModel): any {
    return state.dwFeatureFlag;
  }

  @Selector()
  public static returnDeveloperWorkspaceFlag(
    state: FeatureFlagsStateModel,
  ): any {
    return state.isDevelopersAwsWorkspacesAvailable;
  }

  @Selector()
  public static returnFeatureFlags(state: FeatureFlagsStateModel): any {
    return state;
  }
}

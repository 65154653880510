import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInProgressInterceptor } from './api-in-progress-state-interceptor';
import { BaseUrlInterceptor } from './base-url-interceptor';
import { TokenInterceptor } from './token-interceptor';
import { CheckDisabledUserInterceptor } from './check-disabled-user-interceptor';
export * from './api-in-progress-state-interceptor';
export * from './base-url-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiInProgressInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CheckDisabledUserInterceptor, multi: true },
];

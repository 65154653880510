<div
  class="matero-container-wrap matero-navbar-side matero-header-fixed"
  [ngClass]="{
    'matero-sidenav-collapsed': options.sidenavCollapsed,
    'mpr-block-interactions': !isTermsAccepted
  }"
  dir="ltr"
>
  <header>
    <app-header
      [showToggle]="!options.sidenavCollapsed"
      (toggleSidenav)="sidenav.toggle()"
    >
    </app-header>
  </header>
  <mat-sidenav-container
    class="matero-container mpr-left-nav-pages"
    autosize
    autoFocus
  >
    <mat-sidenav
      #sidenav
      class="matero-sidenav"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.sidenavOpened && !isOver"
      (openedChange)="sidenavOpenedChange($event)"
      (closedStart)="sidenavCloseStart()"
      fixedInViewport="true"
      fixedTopGap="68"
      fixedBottomGap="0"
      id="mpr-mat-sidebar"
    >
      <app-sidebar></app-sidebar>
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content-wrap">
      <mpr-subheader [isDashboard]="isDashboard"></mpr-subheader>
      <main
        class="main-content"
        [ngClass]="
          defaultProjectValue.projectId === '' && numberOfProjects !== 0
            ? 'main-content-no-project-selected'
            : ''
        "
      >
        <div class="matero-content">
          <router-outlet></router-outlet>
        </div>
      </main>
      <footer>
        <app-footer></app-footer>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-template #changeSfRoleDialog>
  <div class="mpr-mat-dialog-container mpr-mat-dialog-small">
    <div mat-dialog-content fxLayout="row">
      <mat-spinner diameter="25" color="accent"></mat-spinner>&nbsp;Connecting
      to Snowflake
    </div>
  </div>
</ng-template>

import { GlobalAttributesValueModel } from '../global-attributes/global-attributes.model';

export default interface ManageProjectModel {
  loader: boolean;
  message: MessageDetails | string;
  res: projectDetails;
  status_code?: string;
}
export interface projectDetails {
  credit: number;
  dwAccess: boolean;
  externalProjectAccountsEnabled: boolean;
  hasPowerUsers: boolean;
  projectAttributes?: GlobalAttributesValueModel[];
  projectName: string;
  projectNickname: string;
  projectNumber: string;
  projectRestrictedDataUseGroups: string[] | null;
  projectSize: '';
  rdsEnabled: boolean;
}

export interface MessageDetails {
  projectId: string;
}

export interface formValue {
  [key: string]: string | any;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { customSort } from '@shared/utils';
import { UserProject } from '@theme/interfaces';
import {
  DomainListState,
  GetAllowedDomains,
  LoadAllUserdata,
  LoadAllUserProjectsForPlatFormAdmin,
  UserProjectState,
  UserState,
} from 'app/state';
import { ProjectDetails } from 'app/state/console-stats/console-stats-state-model';
import { GetProjectCount } from 'app/state/console-stats/console-stats.actions';
import { ConsoleStatsState } from 'app/state/console-stats/console-stats.state';
import {
  FeatureFlagsState,
  FeatureFlagsStateModel,
} from 'app/state/feature-flags';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import {
  AddUserToProject,
  ResetEditUserState,
  ResetUserListState,
} from 'app/state/project-user/project-user.actions';
import { ProjectUserState } from 'app/state/project-user/project-user.state';
import { UserList } from 'app/state/project-user/user-list-model';
import UserStateModel from 'app/state/user/user-state-model';
import { catchError, Observable, Subscription, throwError, timer } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-add-to-project',
  templateUrl: './add-to-project.component.html',
  styleUrls: ['./add-to-project.component.scss'],
})
export class AddToProjectComponent implements OnInit, OnDestroy {
  @Select(ProjectUserState.getUsersListDetails)
  public UsersDetailsList$?: Observable<UserList[]>;
  @Select(UserProjectState.getAllUserProjects)
  public allProjectsForPlatformAdmin$?: Observable<UserProject[]>;
  @Select(DomainListState.returnAllowedDomains)
  public allowedDomains$!: Observable<string[]>;
  @Select(ConsoleStatsState.getAllProjects)
  public allprojects$?: Observable<UserProject[]>;
  @Select(UserState.getAllUsers)
  public listDetails!: Observable<UserStateModel>;
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @Select(ProjectUserState.getLoaderStatus)
  public showSpinner$?: Observable<boolean>;
  @Select(ProjectUserState.returnUsersDetails)
  public userDetails$?: Observable<ProjectUserList>;
  public allRestrictedGroupsForProject: string[] = [];
  public allowedExternalDomains: Array<string> = [];
  public allowedInternalDomains: Array<string> = [];
  public canChangeBedrockAccessStatus = true;
  public canChangePowerUserStatus = true;
  public config: ProjectUserList;
  public deltaProjects: ProjectDetails[] = [];
  public isPowerUserApplicable = false;
  public isSelectedUserUnsupportedForSelectedProject = false;
  public returnPath: string;
  public selectProjectDetails: any = {};
  public selectedProject!: UserProject;
  public selectedProjectId = '';
  public selectedUserDetails: any = {};
  public selectedUserId = '';
  public showSpinner = true;
  public sortedValue: ProjectDetails[] = [];
  private allSubscription = new Subscription();
  private requestHeaders?: MprHttpHeaderModal = {};

  constructor(
    private route: ActivatedRoute,
    public store: Store,
    private router: Router,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.selectedUserId = atob(route.snapshot.params['emailId']);
    this.returnPath = route.snapshot.data['back'];

    this.config = {
      ec2Status: '',
      emailId: '',
      firstName: '',
      isPowerUser: false,
      lastName: '',
      machineSize: '',
      projectId: this.selectedProjectId,
      projectRestrictedDataUseGroups: [],
      roleName: '',
      userId: '',
      userStatus: '',
      powerUserFeatures : {
        isHLBedrockAccess : false
      }
    };
  }

  public changeProject(selectedProject: string): void {
    this.showSpinner = true;
    this.allprojects$?.subscribe((projects: any) => {
      this.isSelectedUserUnsupportedForSelectedProject = false;
      this.selectProjectDetails = projects.find(
        (projectDetails: any) => projectDetails.projectId === selectedProject
      );
      this.selectedProjectId = selectedProject;
      this.store.dispatch(
        new GetAllowedDomains(
          this.selectProjectDetails.projectId,
          UserProjectRoleEnum.PLATFORM_ADMIN
        )
      );
      this.getFormattedValue(this.selectProjectDetails);
    });
  }

  public getFormattedValue(selectProjectDetails?: any): void {
    const userDetails = {
      ec2Status: this.selectedUserDetails[0].ec2Status,
      emailId: this.selectedUserDetails[0].emailId,
      firstName: this.selectedUserDetails[0].userName.split(' ')[0],
      isPowerUser: false,
      lastName: this.selectedUserDetails[0].userName.split(' ')[1],
      machineSize: '',
      projectRestrictedDataUseGroups: [],
      roleName: '',
      userId: this.selectedUserDetails[0].userId,
      userStatus: this.selectedUserDetails[0].userStatus,
      projectId: selectProjectDetails?.projectId
        ? selectProjectDetails?.projectId
        : this.selectedProjectId,
      powerUserFeatures: this.selectedUserDetails[0].powerUserFeatures || {}
    };
    this.allRestrictedGroupsForProject =
      selectProjectDetails?.projectRestrictedDataUseGroups;
    this.isPowerUserApplicable = selectProjectDetails?.hasPowerUsers;
    this.config = { ...userDetails };
    this.showSpinner = false;
  }

  public getProjectName(project: ProjectDetails): string {
    return `${project.projectNumber}_${project.projectNickname}`;
  }
  public navigateBack(): void {
    this.router.navigate([this.returnPath]);
  }
  ngOnDestroy(): void {
    this.allSubscription.unsubscribe();
    this.store.dispatch(new ResetEditUserState());
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    if (this.selectedUserId) {
      const featureFlags: FeatureFlagsStateModel = this.store.selectSnapshot(
        FeatureFlagsState.returnFeatureFlags
      );
      this.allowedInternalDomains = featureFlags.internalDomainList;
      this.store.dispatch([
        new GetProjectCount('True'),
        new LoadAllUserProjectsForPlatFormAdmin(this.selectedUserId),
        new LoadAllUserdata(),
      ]);
      this.allprojects$?.subscribe((projects: any) => {
        if (projects && projects.length > 0) {
          this.allProjectsForPlatformAdmin$?.subscribe(
            (projectsForSelectedUser: any) => {
              if (
                projectsForSelectedUser &&
                projectsForSelectedUser.length > 0
              ) {
                this.deltaProjects = projects.filter(
                  (el: any) =>
                    !projectsForSelectedUser.find(
                      (element: any) => element.projectId === el.projectId
                    )
                );
                this.sortedValue = customSort(this.deltaProjects);
                this.requestHeaders = {};
                this.requestHeaders[HeaderParams.PROJECTID] =
                  this.selectedProjectId;
                this.requestHeaders[HeaderParams.ROLENAME] =
                  UserProjectRoleEnum.PLATFORM_ADMIN;
                this.store.dispatch(
                  new GetAllowedDomains(
                    this.selectedProjectId,
                    UserProjectRoleEnum.PLATFORM_ADMIN
                  )
                );
                this.listDetails?.subscribe((userDetail: any) => {
                  if (userDetail && userDetail.userInfo) {
                    this.selectedUserDetails = userDetail.userInfo.filter(
                      (el: any) => el.emailId === this.selectedUserId
                    );
                    if (this.selectedUserDetails[0].userStatus === 'disabled')
                      this.userNavigateBack('/platformAdmin/users');
                    this.getFormattedValue();
                  }
                });
              }
            }
          );
        }
      });

      this.allowedDomains$?.subscribe((externalDomains: Array<string>) => {
        this.allowedExternalDomains = externalDomains;
        const selectedUserEmailDomain =
          this.selectedUserDetails[0].emailId.split('@')[1];
        const isinternalEmail = this.allowedInternalDomains.findIndex(
          (x) => x === selectedUserEmailDomain
        );
        if (isinternalEmail <= -1) {
          const isExternalDomain = this.allowedExternalDomains.findIndex(
            (x) => x === selectedUserEmailDomain
          );
          if (isExternalDomain <= -1) {
            this.isSelectedUserUnsupportedForSelectedProject = true;
          }
        }
      });
    }
  }

  public onSubmit(): void {
    this.store.dispatch(new ResetUserListState());
    this.showSpinner = true;
    const userList = [];
    userList.push(this.config);
    this.store.dispatch(
      new AddUserToProject(userList, UserProjectRoleEnum.PLATFORM_ADMIN)
    );
    if (this.UsersDetailsList$) {
      this.allSubscription = this.UsersDetailsList$.pipe(
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
          });
          this.showSpinner = false;
          return throwError(() => new Error(''));
        })
      ).subscribe((response: Array<UserList>) => {
        if (response && response.length > 0) {
          if (response[0].isSuccess === true) {
            this.alertMsgService.success({
              body: 'User added to the project successfully.',
            });
            timer(1000).subscribe(() => {
              this.navigateBack();
            });
            this.showSpinner = false;
          } else {
            if (response[0].message) {
              this.alertMsgService.error({
                body: response[0].message,
              });
              this.showSpinner = false;
            }
          }
        }
      });
    }
  }

  public userNavigateBack(route = `/platformAdmin/users/`): void {
    window.location.href = route;
  }
}

<div class="mpr-dashboard-loader" *ngIf="showSpinner; else showUserForm">
  <div class="mpr-noresult-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</div>
<ng-template #showUserForm>
  <div *ngIf="isPlatformAdmin && !fromUserList; else showAdmin">
    <mat-label for="SELECT PROJECT" class="mpr-select-project" role="none"
      >SELECT PROJECT</mat-label
    >

    <div>
      <mat-form-field
        class="mpr-mat-select-field mpr-select-field-width"
        floatLabel="never"
        appearance="outline"
      >
        <mat-select
          placeholder="Select your project"
          [(ngModel)]="selectedProjectId"
          disableOptionCentering
          (ngModelChange)="changeProject(selectedProjectId)"
        >
          <mat-option
            *ngFor="let project of sortedValue"
            [value]="project.projectId"
          >
            {{ project | projectShortName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <ng-template #showAdmin>
    <div class="mpr-project">PROJECT : {{ selectedProject.projectId }}</div>
  </ng-template>
  <form #userForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <mat-expansion-panel
      [class.mpr-disabled-form]="!selectedProjectId"
      class="mpr-margin-top"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]="true"
      *ngFor="let userDetail of config.projectUsersList; let i = index"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="mpr-workspace-header">
            <span
              class="mpr-user-name-title"
              *ngIf="
                (userDetail.firstName || userDetail.lastName) &&
                  userDetail.roleName;
                else showNewUsers
              "
              >{{ userDetail.firstName }} {{ userDetail.lastName }} ({{
                userDetail.roleName | replaceRoleName
              }})</span
            >
          </div>
          <div *ngIf="i > 0" (click)="removeUser(i)">
            <img
              src="assets/images/delete.svg"
              alt=""
              class="mpr-delete-icon"
            />
            <span class="mpr-delete">Delete</span>
          </div>
          <ng-template #showNewUsers>
            <span class="mpr-user-name-title">New User</span>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mpr-user-form
        [disableInputFields]="disableInputFields"
        [data]="config.projectUsersList[i]"
        [group]="'userDetail' + i"
        [projectId]="selectedProjectId"
        [projectPoweruser]="isPowerUserApplicable"
        [allowedExternalDomains]="allowedExternalDomains"
        [canChangeBedrockAccessStatus] = "canChangeBedrockAccessStatus"
      ></mpr-user-form>
    </mat-expansion-panel>
    <div class="mpr-actions">
      <div>
        <button type="button" (click)="addAddress()" class="mpr-add-more-user"
        [class.mpr-disabled-form]="!selectedProjectId"
        [disabled]="!selectedProjectId"
        >
          Add More User(s)
        </button>
      </div>
      <div>
        <button type="button" (click)="navigateBack()" class="mpr-cancel">
          Cancel
        </button>
        <button
          type="submit"
          class="mpr-submit-user"
          [ngClass]="(!userForm.form.valid || !selectedProjectId || !formIsValid()) ? 'mpr-disabled' : ''"
          [disabled]="!userForm.form.valid || !selectedProjectId"
        >
          Confirm
        </button>
      </div>
    </div>
  </form>
</ng-template>

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services';
import { environment } from '@env/environment';
import { catchError, map, Observable, of, throwError } from 'rxjs';

@Injectable()
export class CheckDisabledUserInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }
    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            //Intercept only our BE APIS and skip others like uploads, images etc 
            if (!request.url.includes(environment.apiBaseUrl)) return event;
            if (event instanceof HttpResponse) {
                // disabled user check for lambda based APIs 
                if (event.body.status_code === 403 && event.body.message === 'disabledUser') {
                    this.authService.logoutAndRedirectToDisabledUser();
                }
            }
            return event;
        }),
            catchError((error: HttpErrorResponse) => {
                // disabled user check for ecs service based APIs 
                if (error.error.message === 'disabledUser' && error.error.status_code === 403) {
                    this.authService.logoutAndRedirectToDisabledUser();
                    return of();
                }
                return throwError(() => error);
            })
        );
    }
}

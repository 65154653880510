import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LOCALHOST, USER_IDLE_CONFIG } from '@core/constants';
import { JwtTokenModel, TokenApiResponseModel } from '@core/interfaces';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { RouteState } from 'app/state';
import {
  LoggedinUserState,
  LoggedinUserStateModel,
  SetDashboardLoaderState,
  SetLoggedinUserState,
} from 'app/state/';
import * as dayjs from 'dayjs';
import { CookieOptions } from 'ngx-cookie-service';
import {
  catchError,
  delay,
  map,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { AuthConstants } from '../enums';
import { MprCookieService } from './mpr-cookie.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private prodCookiesCleanedUp = false;
  private timeout = 60;
  private tokenSubscription = new Subscription();

  constructor(
    private injector: Injector,
    private mprCookieService: MprCookieService,
    private store: Store,
    private tokenService: TokenService,
  ) {}

  public authenticate(onTokenExpireShowTimeout: boolean = false): void {
    const idToken = this.getIdToken();
    let tokenData: JwtTokenModel = {};

    // Token can contain anything, no type needed for now.
    if (idToken) tokenData = this.tokenService.parseToken(idToken);

    // If not present or corrupted (unlikely) or expired (can happen) redirect to recreate it.
    if (!idToken || Object.keys(tokenData).length === 0) {
      return this.redirectToLogin();
    }

    if (this.isTokenExpired(tokenData[AuthConstants.EXPIRY])) {
      return onTokenExpireShowTimeout
        ? this.redirectToSessionTimeout()
        : this.logoutAndRedirectToLogin();
    }

    // Save to State
    this.saveToUserState({ ...tokenData });

    this.redirectToLastRoute();
  }

  public check(): boolean {
    // Before any auth clean up 'prod' cookies
    if (
      !this.prodCookiesCleanedUp &&
      !environment.production &&
      !window.location.host.includes(`${LOCALHOST}:`)
    ) {
      this.cleanUpTokens(true, true);
      this.prodCookiesCleanedUp = true;
    }

    // If cookie has expired or got removed
    const idToken = this.getCookie(AuthConstants.ID_TOKEN);
    if (!idToken) return false;

    const tokenExpiry = this.getIdTokenExpiry();

    if (tokenExpiry === 0) return false;

    const tokenValid = !this.isTokenExpired(tokenExpiry, true);

    // If we have a valid token but we do not have the state populated, then populate the state (Once)
    if (
      tokenValid &&
      !this.store.selectSnapshot(LoggedinUserState.getLoggedInUserId)
    ) {
      // Token can contain anything, no type needed for now.
      const idTokenData: JwtTokenModel = this.tokenService.parseToken(idToken);

      // Update Logged-in State with updated info.
      this.saveToUserState(idTokenData);

      const triggerExpiryMs = this.getMillisecondsToTokenExpiry(tokenExpiry);
      this.handleLogoutOnTokenExpiry(triggerExpiryMs);
    }

    // Date.now is UTC time : no locale involved
    return tokenValid;
  }

  public cleanUpTokens(
    includingRefreshToken = true,
    prodCookiesOnly = false,
  ): void {
    const host = window.location.host;
    let domain = `.${host}`;

    // IMP: This is only for one scenario when we are on non-prod,we want to clean up prod cookies.
    if (prodCookiesOnly) {
      domain = `.${new URL(environment.prodEnvUrl).host}`;
    }

    this.mprCookieService.delete(AuthConstants.ID_TOKEN, '/', domain);
    this.mprCookieService.delete(AuthConstants.ACCESS_TOKEN, '/', domain);

    if (includingRefreshToken)
      this.mprCookieService.delete(AuthConstants.REFRESH_TOKEN, '/', domain);
  }

  public getCookie(name: string): string {
    return this.mprCookieService.getContent(name);
  }

  public getIdToken(): string {
    return this.getCookie(AuthConstants.ID_TOKEN);
  }

  public getIdTokenExpiry(getLatestValue = false): number {
    // Check if this cookie
    let tokenExpiry = 0;

    if (!getLatestValue) {
      tokenExpiry = this.store.selectSnapshot(
        LoggedinUserState.getLoggedinUserTokenExpiry,
      );
    }

    // For refresh scenarios token expiry will not be in state
    if (!tokenExpiry) {
      // Token can contain anything, no type needed for now.
      tokenExpiry =
        this.tokenService.parseToken(this.getIdToken())[AuthConstants.EXPIRY] *
        1000;
    }

    return !tokenExpiry ? 0 : tokenExpiry;
  }

  public getMillisecondsToTokenExpiry(tokenExpiry: number): number {
    const currentTime = dayjs().unix() * 1000;
    const triggerExpiryMs = tokenExpiry - currentTime;
    return triggerExpiryMs;
  }

  public getRefreshToken(): string {
    return this.getCookie(AuthConstants.REFRESH_TOKEN);
  }

  public getUserId(): string {
    let userId = this.store.selectSnapshot(LoggedinUserState.getLoggedInUserId);

    // For refresh scenarios token expiry will not be in state
    if (!userId) {
      // Token can contain anything, no type needed for now.
      userId =
        this.tokenService.parseToken(this.getIdToken())[AuthConstants.ID] ?? '';
    }

    return userId;
  }

  public handleLogoutOnTokenExpiry(tokenExpiryInMilliSecs: number): void {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null)
      .pipe(delay(tokenExpiryInMilliSecs))
      .subscribe((expired) => {
        setTimeout(() => {
          let idTokenExpiryMs = 0;
          /**
           * Edge Case Handling :
           * If user opened 2 or more tabs and one of them is older and not in sync with the reality (the latest one being worked on by user)
           * We cannot just blindly logout the user (in the first one he opened) as the user may be in the middle of an operation in the other tabs where its entirely possible that
           * tokens get refreshed (due to coming back from CKAN or from the user doing long running operations) or even that he might have logged out from the other one.
           * hence check if the token is still valid and reset the timer: call same function.
           **/
          const tokenExpiry = this.getIdTokenExpiry(true);
          if (tokenExpiry > 0) {
            idTokenExpiryMs = this.getMillisecondsToTokenExpiry(tokenExpiry);
          }

          // If its negative or 0 logout the user
          if (idTokenExpiryMs > 0) {
            this.handleLogoutOnTokenExpiry(idTokenExpiryMs);
          } else if (!sessionStorage.getItem('long-operation-in-progress')) {
            // Only logout the user if he was idle else refresh his tokens
            if (localStorage.getItem(USER_IDLE_CONFIG.localStorageKey)) {
              this.logoutAndRedirectToLogin();
            } else {
              this.refreshToken().subscribe(() => true);
            }
          }
        }, 1000);
      });
  }

  public isTokenExpired(
    expiry: number,
    isInMilliSeconds: boolean = false,
  ): boolean {
    const multiplyBy = isInMilliSeconds ? 1 : 1000;
    return Date.now() > expiry * multiplyBy;
  }

  public logout(): void {
    const clientId = this.store.selectSnapshot(
      LoggedinUserState.getAuth0ClientId,
    );
    window.location.href = `${environment.ckanUrl}/user/_logout`;
    return;
  }
  public logoutAndRedirectToDisabledUser(): void {
    sessionStorage.clear();
    localStorage.removeItem(USER_IDLE_CONFIG.localStorageKey);
    this.cleanUpTokens(false);
    const clientId = environment.auth0ClientId;
    const currentUrlWithDomain = window.location.origin;
    window.location.href = `${environment.auth0Domain}v2/logout?client_id=${clientId}&returnTo=${currentUrlWithDomain}/pages/failed-login?msg=loginFailedDisabledUser`;
  }

  public logoutAndRedirectToLogin(): void {
    sessionStorage.clear();
    localStorage.removeItem(USER_IDLE_CONFIG.localStorageKey);
    this.cleanUpTokens(false);
    const clientId = environment.auth0ClientId;
    const currentUrlWithDomain = window.location.origin;
    window.location.href = `${environment.auth0Domain}v2/logout?client_id=${clientId}&returnTo=${currentUrlWithDomain}`;
  }

  public redirectToLastRoute(): void {
    const lastRoute = this.store.selectSnapshot(RouteState.getRoute);
    const currentUrlWithDomain = window.location.origin;
    window.location.href = `${currentUrlWithDomain}${lastRoute}`;
  }

  public redirectToLogin(): void {
    sessionStorage.clear();
    this.cleanUpTokens();
    const clientId = environment.auth0ClientId;
    window.location.href = `${environment.auth0Domain}v2/logout?client_id=${clientId}&returnTo=${environment.apiBaseUrl}login`;
    return;
  }

  public refreshToken(): Observable<TokenApiResponseModel> {
    const http: HttpClient = this.injector.get(HttpClient);
    const refreshToken = this.getRefreshToken();
    const clientId = environment.auth0ClientId;
    const clientSecret = environment.auth0ClientSecret;
    const clientIdClientSecret = btoa(`${clientId}:${clientSecret}`);
    const headers = new HttpHeaders().set(
      AuthConstants.AUTHORIZATION_FIELD,
      `Basic ${clientIdClientSecret}`,
    );
    const params = new HttpParams()
      .set(AuthConstants.AUTH_GRANT_TYPE_FIELD, AuthConstants.REFRESH_TOKEN)
      .set(AuthConstants.CLIENT_ID_FIELD, clientId)
      .set(AuthConstants.REFRESH_TOKEN, refreshToken);

    return http
      .post<TokenApiResponseModel>(
        `${environment.auth0Domain}oauth/token`,
        params,
        { headers },
      )
      .pipe(
        map((tokenResponse: TokenApiResponseModel) => {
          // Token can contain anything, no type needed for now.
          const idTokenData: JwtTokenModel = this.tokenService.parseToken(
            tokenResponse[AuthConstants.ID_TOKEN],
          );
          // Update Logged-in State with updated info.
          this.saveToUserState(idTokenData);
          // Set the same in a Cookie
          const cookieOptions: CookieOptions = {
            expires: new Date(this.getIdTokenExpiry()),
            path: '/',
          };
          this.mprCookieService.set(
            AuthConstants.ID_TOKEN,
            tokenResponse[AuthConstants.ID_TOKEN],
            cookieOptions,
          );
          this.mprCookieService.set(
            AuthConstants.ACCESS_TOKEN,
            tokenResponse[AuthConstants.ACCESS_TOKEN],
            cookieOptions,
          );
          // Auth0 does not reuse older refresh tokens (Refresh Token Rotation), everytime other tokens are refreshed we get a new refresh token as well
          // Unlike cognito where same refresh token could be used multiple time till the EOL for refresh token.
          const nextMonthDate = new Date(this.getIdTokenExpiry());
          nextMonthDate.setDate(nextMonthDate.getDate() + 30);
          const cookieOptionsRefresh: CookieOptions = {
            expires: nextMonthDate,
            path: '/',
          };
          this.mprCookieService.set(
            AuthConstants.REFRESH_TOKEN,
            tokenResponse[AuthConstants.REFRESH_TOKEN],
            cookieOptionsRefresh,
          );

          const tokenExpiry = this.getIdTokenExpiry();
          const idTokenExpiryMs =
            this.getMillisecondsToTokenExpiry(tokenExpiry);
          this.handleLogoutOnTokenExpiry(idTokenExpiryMs);
          return tokenResponse;
        }),
        catchError(() => {
          this.logoutAndRedirectToLogin();
          // return an observable error message
          return throwError(() => new Error('Refresh Token Has Expired'));
        }),
      );
  }

  public signout(): void {
    const clientId = environment.auth0ClientId;
    const currentUrlWithDomain = window.location.origin;
    window.location.href = `${environment.auth0Domain}v2/logout?client_id=${clientId}&returnTo=${currentUrlWithDomain}/pages/logged-out`;
  }

  private redirectToSessionTimeout(): void {
    sessionStorage.clear();
    this.cleanUpTokens();
    const clientId = environment.auth0ClientId;
    const currentUrlWithDomain = window.location.origin;
    window.location.href = `${environment.auth0Domain}v2/logout?client_id=${clientId}&returnTo=${currentUrlWithDomain}/pages/session-time-out`;
  }

  

  private saveToUserState(tokenData: JwtTokenModel): void {
    const isGivenNameAvailable =
      tokenData[AuthConstants.GIVEN_NAME] &&
      tokenData[AuthConstants.GIVEN_NAME] !== '';
    const isFamilyNameAvailable =
      tokenData[AuthConstants.FAMILY_NAME] &&
      tokenData[AuthConstants.FAMILY_NAME] !== '';
    let displayName = '';
    if (isGivenNameAvailable && isFamilyNameAvailable) {
      displayName = `${tokenData[AuthConstants.GIVEN_NAME]} ${
        tokenData[AuthConstants.FAMILY_NAME]
      }`;
    } else if (isFamilyNameAvailable) {
      displayName = tokenData[AuthConstants.FAMILY_NAME];
    } else if (isGivenNameAvailable) {
      displayName = tokenData[AuthConstants.GIVEN_NAME];
    } else {
      displayName = tokenData[AuthConstants.EMAIL].split('@')[0];
    }

    const userData: LoggedinUserStateModel = {
      auth0ClientId: tokenData[AuthConstants.CLIENT_ID],
      userId: tokenData[AuthConstants.ID] ?? tokenData[AuthConstants.EMAIL],
      name: displayName,
      email: tokenData[AuthConstants.EMAIL],
      tokenExpiry: tokenData[AuthConstants.EXPIRY] * 1000, //milliseconds
    };

    this.store.dispatch(new SetLoggedinUserState(userData));
    this.store.dispatch(new SetDashboardLoaderState(true));
  }
}

export interface FeatureFlagsStateModel {
  dwFeatureFlag: string;
  externalContactUsUrl: string;
  externalProjectAccountsFeatureFlag: string;
  internalContactUsUrl: string;
  internalDomainList: Array<string>;
  isAccountingFeatureFlagEnabled: string;
  isAwsWorkspacesAvailable: string;
  isBillingFeatureFlagEnabled: string;
  isDevelopersAwsWorkspacesAvailable: string;
  isForstaFeatureFlagEnabled: string;
  isHeavyLiftingFlagAvailable: string;
  isManageProjects: string;
  isManageUsers: string;
  isProjectAdminConsole: string;
  isRecurringStagingUploadAvailable: string;
  isStagingAccessAvailable: string;
  isStataFeatureFlagAvailable: string;
  isVscodeFeatureFlagAvailable: string;
  rdsFeatureFlag: string;
  serviceAccountFeatureFlag: string;
}
